import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, UntypedFormArray, FormControl } from '@angular/forms';
import { AppConfigService } from '../services/app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '../services/notification.service';
import { PolicyService } from '../services/policy.service';
import { CampaignService } from '../services/campaign.service';
import { TrackingService } from '../services/tracking.service';
import { ProgressIndicatorService } from '../services/progress-indicator.service';
import { Certificate } from '../models/magenta/certificate';
import { Client } from '../models/magenta/client';
import { ContentSnippet } from '../models/content-snippet';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ImportIdolCreatePolicyRequest } from '../models/dto/get-policy/import-idol-create-policy-request';
import { QuotezoneData } from '../models/tracking/quotezone-data';
import { ByExternalRefRequest } from '../models/dto/get-policy/by-external-ref-request';
import { IncompleteQuotePersonalDetailsRequest } from '../models/dto/save-policy/incomplete-quote-personal-details-request';
import { ClientNameUpdate } from '../models/dto/save-policy/client-name-update';


@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})

export class PersonalComponent implements OnInit, OnDestroy {

  public personalDetailForm = this._formBuilder.group({
    postcodeSearch: this._formBuilder.group({
      postcode: new FormControl<string | null>(null, {
        validators: [Validators.minLength(6), Validators.maxLength(8)]
      }),
      postcodeLookupId: new FormControl<number | null>(null)
    }),
    address: this._formBuilder.group({
      address1: new FormControl<string | null>(null, {
        validators: [Validators.required]
      }),
      address2: new FormControl<string | null>(null),
      address3: new FormControl<string | null>(null),
      address4: new FormControl<string | null>(null, {
        validators: [Validators.required]
      }),
      address5: new FormControl<string | null>(null),
      postcode: new FormControl<string | null>(null, {
        validators: [Validators.required]
      })
    }),
    clientNames: this._formBuilder.array([]),
    contactAndMarketing: this._formBuilder.group({
      telDaytime: new FormControl<string | null>(null, {
        validators: [Validators.required, Validators.pattern('[0-9 ]{9,15}')]
      }),
      email: new FormControl<string | null>(null, {
        validators: [Validators.required, Validators.email, Validators.maxLength(254)]
      }),
      optInMail: new FormControl<boolean>(false, {
        nonNullable: true
      }),
      privacyStatementConsent: new FormControl<boolean>(false, {
        validators: [Validators.requiredTrue],
        nonNullable: true
      })
    })
  });

  public certificate: Certificate;
  public showExternalLoadError = false;
  public showMapsInfoMessage = false;
  public isQuotezone = false;
  public showBackBtn = false;
  public showSaveBtn = false;
  public optInStatementContent = '';
  public optInStatementCheckboxLabelContent = '';
  public privacyStatementCheckboxLabelContent = '';
  public personalDetailFormSubmitted = false;
  public readonly postcodeSearchFormGroupName = 'postcodeSearch';
  public readonly addressFormGroupName = 'address';
  public clientTitles: string[];

  // Subscriptions.
  private readonly _subscriptions: Subscription[] = [];

  constructor(public dialog: MatDialog,
              private _appConfigService: AppConfigService,
              private _spinnerService: NgxSpinnerService,
              private _notificationService: NotificationService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute,
              private _formBuilder: FormBuilder,
              private _policyService: PolicyService,
              private _campaignService: CampaignService,
              private _trackingService: TrackingService,
              private _progressIndicatorServicer: ProgressIndicatorService) {

  }

  ngOnInit(): void {

    // Subscribe to certificate.
    this._subscriptions.push(this._policyService.certificate
                              .subscribe(cert => {
                                
                                if(cert) {
                                  // Load form values.
                                  this.personalDetailForm.controls.address.controls.address1.setValue(cert.clients[0]?.address?.address1);
                                  this.personalDetailForm.controls.address.controls.address2.setValue(cert.clients[0]?.address?.address2);
                                  this.personalDetailForm.controls.address.controls.address3.setValue(cert.clients[0]?.address?.address3);
                                  this.personalDetailForm.controls.address.controls.address4.setValue(cert.clients[0]?.address?.address4);
                                  this.personalDetailForm.controls.address.controls.address5.setValue(cert.clients[0]?.address?.address5);
                                  this.personalDetailForm.controls.address.controls.postcode.setValue(cert.clients[0]?.address?.postCode);
                                  this.personalDetailForm.controls.contactAndMarketing.controls.telDaytime.setValue(cert.clients[0]?.telDaytime);
                                  this.personalDetailForm.controls.contactAndMarketing.controls.email.setValue(cert.clients[0]?.email);

                                  // Load client name collection for aggs.
                                  if(this._policyService.isMagentaSourceQuoteZone(cert.source)) {
                                    this.populateClientsFormArray(cert.clients);
                                  }

                                  // Set no mail value. Incoming can be null.
                                  const noMail = cert.clients[0]?.noMail ?? true;
                                  this.personalDetailForm.controls.contactAndMarketing.controls.optInMail.setValue(!noMail);

                                  this.certificate = cert;

                                  this.initMapsInfoMessage();
                                }
                              })
    );

    // Check for Quotezone request params.
    // Note: We were previously using the param/value 'quotezone=1' to determine
    // whether the incoming request is Quotezone. After moving to the new quote journey
    // and intergrating, FM requested we change the param to detect Quotezone traffic to 
    // now use the 'Comp=quotezone' param/value pair.
    this.isQuotezone = this._activatedRoute.snapshot.queryParamMap.get('Comp') === 'quotezone';

    if(this.isQuotezone) {
      this._spinnerService.show();

      const quotezoneData: QuotezoneData = {
        refId: this._activatedRoute.snapshot.queryParamMap.get('RefID')
      };

      const importIdolCreatePolicyRequest: ImportIdolCreatePolicyRequest = {
        policyReference: quotezoneData.refId,
        generateQuote: false
      };

      // Note: After moving to new journey, FM requested that we use importIdolCreatePolicy
      // method for Quotezone instead of getting policy by external reference.
      this._policyService.importIdolCreatePolicy(importIdolCreatePolicyRequest)
        .subscribe(cert => {   
          // Set the campaign.
          this._campaignService.setActiveCampaign(cert.campaign, false);

          // Push certificate to service.
          this._policyService.setCertificate(cert);
          this._trackingService.setQuotezoneData(quotezoneData);
          this._spinnerService.hide();
        },
        err => {
          console.log(err);
          this.showExternalLoadError = true;
          this._spinnerService.hide();
        });
    } else {
      // Check for Quotezone data previously set.
      this.isQuotezone = this._trackingService.isQuotezoneDataAvailable();
    }

    // Load content snippet data.
    this.optInStatementContent = this._appConfigService.getAgentContentSnippetById('optInStatement').value;
    this.optInStatementCheckboxLabelContent = this._appConfigService.getAgentContentSnippetById('optInStatementCheckboxLabel').value;
    this.privacyStatementCheckboxLabelContent = this._appConfigService.getAgentContentSnippetById('privacyStatementCheckboxLabel').value;

    // Setup Quotezone traffic.
    if(this.isQuotezone) {
      this._progressIndicatorServicer.hideSteps([0, 1, 2]);
      this.clientTitles = this._policyService.getClientTitles();
    }

    // Set button display status.
    this.showBackBtn = !this.isQuotezone;
    this.showSaveBtn = !this.isQuotezone;
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  /**
   * Initialises the display of the Money & Pensions Service info message.
   * Mandatory to show when a certificate contains a client whose medical
   * AP is greater than or equal to the configured threshold.
   */
  private initMapsInfoMessage(): void {
    if(this._appConfigService.agent?.mapsInfoMessage?.enabled) {
      const apThreshold = this._appConfigService.agent.mapsInfoMessage.medicalApThresholdPerPerson;

      for(let i = 0; i < this.certificate.clients.length; i++) {
        if(this.certificate.clients[i].screening && this.certificate.clients[i].screening.gross >= apThreshold) {
          this.showMapsInfoMessage = true;
          break;
        }
      }
    }
  }

  public get clientNamesFormArray(): UntypedFormArray {
    return this.personalDetailForm.get('clientNames') as UntypedFormArray;
  }

  private populateClientsFormArray(clients: Client[]) {

    this.clientNamesFormArray.clear();

    clients.forEach((client, index) => {
      const reset = index !== 0 && client.firstName === 'Additional';
      const title = reset ? null : client.title;
      const firstName = reset ? null : client.firstName;
      const lastName = reset ? null : client.lastName;

      this.clientNamesFormArray.push(
        this.createClientNameFormGroup(client.certificateClientId, title, firstName, lastName, client.dateOfBirth)
      );
    });
  }

  private createClientNameFormGroup(certificateClientId: number, title: string, firstName: string, lastName: string, dateOfBirth: string): FormGroup {
    return this._formBuilder.group({
      certificateClientId: new FormControl<number>(certificateClientId),
      title: new FormControl<string | null>(title, {
        validators: [Validators.required]
      }),
      firstName: new FormControl<string | null>(firstName, {
        validators: [Validators.required, Validators.maxLength(50)]
      }),
      lastName: new FormControl<string | null>(lastName, {
        validators: [Validators.required, Validators.maxLength(50)]
      }),
      dateOfBirth: new FormControl<string | null>(dateOfBirth)
    });
  }

  public backOnClick(): void {
    this._router.navigate(['/quote']);
  }

  public saveOnClick(generateQuote: boolean): void {
    this.personalDetailForm.markAllAsTouched();
    this.personalDetailFormSubmitted = true;

    if(this.personalDetailForm.controls.address.valid && this.clientNamesFormArray.valid && this.personalDetailForm.controls.contactAndMarketing.valid) {
      this._spinnerService.show();

      const request: IncompleteQuotePersonalDetailsRequest = {
        address: {
          address1: this.personalDetailForm.controls.address.controls.address1.value,
          address2: this.personalDetailForm.controls.address.controls.address2.value,
          address3: this.personalDetailForm.controls.address.controls.address3.value,
          address4: this.personalDetailForm.controls.address.controls.address4.value,
          address5: this.personalDetailForm.controls.address.controls.address5.value,
          postCode: this.personalDetailForm.controls.address.controls.postcode.value,
          addressId: null,
          country: null,
          pclAddessId: null
        },
        calculateRates: false,
        certificateId: this.certificate.certificateId,
        email: this.personalDetailForm.controls.contactAndMarketing.controls.email.value,
        noMail: !this.personalDetailForm.controls.contactAndMarketing.controls.optInMail.value,
        telDaytime: this.personalDetailForm.controls.contactAndMarketing.controls.telDaytime.value,
        clientNameUpdates: null,
        externalPolicyReference: null
      }

      if(this.isQuotezone) {
        request.clientNameUpdates = [];

        for(let i = 0; i < this.clientNamesFormArray.length; i++) {
          const clientNameUpdate: ClientNameUpdate = {
            certificateClientId: this.clientNamesFormArray.at(i).get('certificateClientId').value,
            title: this.clientNamesFormArray.at(i).get('title').value,
            firstName: this.clientNamesFormArray.at(i).get('firstName').value,
            lastName: this.clientNamesFormArray.at(i).get('lastName').value
          };

          request.clientNameUpdates.push(clientNameUpdate);
        }
      }

      this._policyService.savePolicyIncompleteQuotePersonalDetails(request)
        .subscribe(cert => {
          this._policyService.setCertificate(cert);
          this._trackingService.gaFireDistilIdentifyCustomerEvent(cert);
          this._trackingService.gaFireDotdigitalIndentifyEvent(cert);
          this._spinnerService.hide();

          if(generateQuote) {
            this._router.navigate(['/quote-generate']);
          } else {
            this._router.navigate(['/summary']);
          }
        },
        _err => {
          this._spinnerService.hide();
        });
    }
  }

}